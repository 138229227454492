import moment from "moment";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Pinterest from "@mui/icons-material/Pinterest";

const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="25px"
      height="25px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export default function Footer() {
  const showInMapClicked = (your_lat, your_lng) => {
    window.open("https://maps.google.com?q=" + your_lat + "," + your_lng);
  };

  const emailAddresses = [
    {
      email: "support@pyne-mena.com",
      subject: "Hello",
      body: "Hello, ",
    },
    {
      email: "careers@pyne-mena.com",
      subject: "Hello",
      body: "Hello, ",
    },
    {
      email: "franchise@pyne-mena.com",
      subject: "Hello",
      body: "Hello, ",
    },
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">
                  <div>
                    <ul className="footer-style">
                      <li>
                        <div className="footer-icon">
                          <FmdGoodIcon sx={{ color: "white", ml: 0.5 }} />
                        </div>
                        <div className="footer-address">
                          <a
                            href="javascript"
                            onClick={(e) => {
                              e.preventDefault();
                              showInMapClicked(33.879577, 35.54111);
                            }}
                          >
                            Ghazzaoui Office Building, Level 2, Texas Street,
                            Beirut, Lebanon
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <PhoneIcon sx={{ color: "white", ml: 0.5 }} />
                        </div>
                        <div className="footer-address">
                          <a href={"tel:+96171838588"}>+96171838588</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <EmailIcon sx={{ color: "white", ml: 0.5 }} />
                        </div>
                        <div className="footer-address">
                          {emailAddresses.map((address, index) => (
                            <a
                              key={index}
                              href={`mailto:${address.email}?subject=${address.subject}&body=${address.body}`}
                            >
                              {address.email}
                            </a>
                          ))}
                        </div>
                      </li>
                      <li>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                          >
                            <InstagramIcon sx={{ color: "white", ml: 0.5 }} />
                            <a
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={(e) => {
                                openInNewTab(
                                  "https://instagram.com/pynemena?igshid=MzRlODBiNWFlZA=="
                                );
                                e.preventDefault();
                              }}
                              href={
                                "https://instagram.com/pynemena?igshid=MzRlODBiNWFlZA=="
                              }
                            >
                              Instagram
                            </a>
                          </div>
                          <div
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                          >
                            <Pinterest sx={{ color: "white", ml: 0.5 }} />
                            <a
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={(e) => {
                                openInNewTab(
                                  "https://www.pinterest.com/michelnader0730/"
                                );
                                e.preventDefault();
                              }}
                              href={
                                "https://www.pinterest.com/michelnader0730/"
                              }
                            >
                              Pinterest
                            </a>
                          </div>
                          <div
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                          >
                            <TikTokIcon color="white" />
                            <a
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={(e) => {
                                openInNewTab(
                                  "https://www.tiktok.com/@pyne_mena"
                                );
                                e.preventDefault();
                              }}
                              href={"https://www.tiktok.com/@pyne_mena"}
                            >
                              TikTok
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3312.3912171828697!2d35.538921315210516!3d33.879576980653276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDUyJzQ2LjUiTiAzNcKwMzInMjguMCJF!5e0!3m2!1sen!2slb!4v1689756336696!5m2!1sen!2slb"
                  allowFullScreen
                  className="md-height"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: 5,
            columnGap: 5,
          }}
        >
          <span>
            <Link to="/privacy" style={{ color: "grey", fontSize: 12 }}>
              Privacy policy{" "}
            </Link>
          </span>
          <span style={{ color: "grey" }}> | </span>
          <span>
            <Link
              to="/terms-and-conditions"
              style={{ color: "grey", fontSize: 12 }}
            >
              Terms and conditions
            </Link>
          </span>
        </div>

        <div className="credits-wrapper">
          <span>
            Copyright &copy; {moment(new Date()).format("yyyy")} pyne s.a.l All
            rights reserved. Developed by{" "}
            <a href="https://www.compu-vision.me" target="_blanc">
              COMPU-VISION
            </a>
          </span>
        </div>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      {/* <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div> */}
    </div>
  );
}
